<template>
  <v-card class="page-supplierWhiteLabelConsumption fill-height flat pa-5">
    <v-card-title>
      {{ $t('whiteLabelConsumption') }}
    </v-card-title>
    <v-alert
      v-if="!hasWiteLabelAccounts"
      outlined
      prominent
      text
      type="info"
      color="primary"
    >
      {{ $t('noWhiteLabelConfiguration') }}
    </v-alert>
    <v-alert
      v-else
      outlined
      prominent
      text
      type="warning"
      color="orange"
    >
      {{ $t('warningAboutWhiteLabelMonthlyFees') }}
    </v-alert>
    <v-card-text>
      <v-skeleton-loader
        v-if="loading"
        type="card"
      ></v-skeleton-loader>
      <v-expansion-panels v-else>
        <v-expansion-panel
          v-for="monthlyFees in allMonthlyFees"
          :key="monthlyFees.month"
        >
          <v-expansion-panel-header>
            {{ monthlyFees.month }}
            <v-spacer></v-spacer>
            <span class="text-right">
              {{ $t('fees') }}
              {{ $n(monthlyFees.totalFees, "currency", "fr") }}
            </span>
            <span class="text-right mr-5">
              {{ $t('amountDue') }}
              {{ $n(monthlyFees.needToBill, "currency", "fr") }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('description') }}
                    </th>
                    <th class="text-left">
                      {{ $t('amountVATExcluded') }}
                    </th>
                    <th class="text-left">
                      {{ $t('amount') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(line,i) in monthlyFees.bill"
                    :key="i"
                  >
                    <td>{{ line.description }}</td>
                    <td>{{ $n(line.amountWithoutVAT, "currency", "fr") }}</td>
                    <td>{{ $n(line.amount, "currency", "fr") }}</td>
                  </tr>
                  <!-- Balance to pay -->
                  <tr>
                    <td colspan="2" class="text-right font-weight-bold">
                      {{ $t('amountDueWithoutVAT') }}
                    </td>
                    <td>{{ $n(monthlyFees.needToBillWithoutVAT, "currency", "fr") }}</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right font-weight-bold">
                      {{ $t('vat') }}
                    </td>
                    <td>{{ monthlyFees.vatPercentage }}%</td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right font-weight-bold">
                      {{ $t('amountDue') }}
                    </td>
                    <td>{{ $n(monthlyFees.needToBill, "currency", "fr") }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t('detail') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <pre>{{ JSON.stringify(monthlyFees, null, 4) }}</pre>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSupplierWhiteLabelConsumption.i18n.json');

export default {
  name: 'PageSupplierWhiteLabelConsumption',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loading: false,
      hasWiteLabelAccounts: false,
      allMonthlyFees: [],
    };
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
      this.loading = true;
      this.$apiInstance.getSupplierPrivateWarehouses(
        this.$route.params.supplierUUID
      ).then(privateWarehouses => {
        privateWarehouses.forEach(pw=>{
          if(pw.billing) {
            this.hasWiteLabelAccounts = true;
            return true;
          } 
        });

        if(this.hasWiteLabelAccounts) {
          this.$apiInstance
            .getSupplierWhiteLabelMonthlyFees(this.$route.params.supplierUUID)
            .then(
              (allMonthlyFees) => {
                this.allMonthlyFees = allMonthlyFees;
              }
            )
            .finally(() => {
              this.loading = false;
            });
        }else{
          this.loading = false;
        }
      });
    },
  },
};
</script>
