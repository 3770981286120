var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "page-supplierWhiteLabelConsumption fill-height flat pa-5"
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('whiteLabelConsumption')) + " ")]), !_vm.hasWiteLabelAccounts ? _c('v-alert', {
    attrs: {
      "outlined": "",
      "prominent": "",
      "text": "",
      "type": "info",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('noWhiteLabelConfiguration')) + " ")]) : _c('v-alert', {
    attrs: {
      "outlined": "",
      "prominent": "",
      "text": "",
      "type": "warning",
      "color": "orange"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('warningAboutWhiteLabelMonthlyFees')) + " ")]), _c('v-card-text', [_vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "card"
    }
  }) : _c('v-expansion-panels', _vm._l(_vm.allMonthlyFees, function (monthlyFees) {
    return _c('v-expansion-panel', {
      key: monthlyFees.month
    }, [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(monthlyFees.month) + " "), _c('v-spacer'), _c('span', {
      staticClass: "text-right"
    }, [_vm._v(" " + _vm._s(_vm.$t('fees')) + " " + _vm._s(_vm.$n(monthlyFees.totalFees, "currency", "fr")) + " ")]), _c('span', {
      staticClass: "text-right mr-5"
    }, [_vm._v(" " + _vm._s(_vm.$t('amountDue')) + " " + _vm._s(_vm.$n(monthlyFees.needToBill, "currency", "fr")) + " ")])], 1), _c('v-expansion-panel-content', [_c('v-simple-table', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function () {
          return [_c('thead', [_c('tr', [_c('th', {
            staticClass: "text-left"
          }, [_vm._v(" " + _vm._s(_vm.$t('description')) + " ")]), _c('th', {
            staticClass: "text-left"
          }, [_vm._v(" " + _vm._s(_vm.$t('amountVATExcluded')) + " ")]), _c('th', {
            staticClass: "text-left"
          }, [_vm._v(" " + _vm._s(_vm.$t('amount')) + " ")])])]), _c('tbody', [_vm._l(monthlyFees.bill, function (line, i) {
            return _c('tr', {
              key: i
            }, [_c('td', [_vm._v(_vm._s(line.description))]), _c('td', [_vm._v(_vm._s(_vm.$n(line.amountWithoutVAT, "currency", "fr")))]), _c('td', [_vm._v(_vm._s(_vm.$n(line.amount, "currency", "fr")))])]);
          }), _c('tr', [_c('td', {
            staticClass: "text-right font-weight-bold",
            attrs: {
              "colspan": "2"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('amountDueWithoutVAT')) + " ")]), _c('td', [_vm._v(_vm._s(_vm.$n(monthlyFees.needToBillWithoutVAT, "currency", "fr")))])]), _c('tr', [_c('td', {
            staticClass: "text-right font-weight-bold",
            attrs: {
              "colspan": "2"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('vat')) + " ")]), _c('td', [_vm._v(_vm._s(monthlyFees.vatPercentage) + "%")])]), _c('tr', [_c('td', {
            staticClass: "text-right font-weight-bold",
            attrs: {
              "colspan": "2"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('amountDue')) + " ")]), _c('td', [_vm._v(_vm._s(_vm.$n(monthlyFees.needToBill, "currency", "fr")))])])], 2)];
        },
        proxy: true
      }], null, true)
    }), _c('v-expansion-panels', [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(_vm.$t('detail')) + " ")]), _c('v-expansion-panel-content', [_c('pre', [_vm._v(_vm._s(JSON.stringify(monthlyFees, null, 4)))])])], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }